@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import Background from './background.jpg';
html, body{
    /* box-sizing: content-box; */
    width: 100%;
    height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}
#root{
    /* box-sizing: content-box; */
    width: 100%;
    height: 100%
}

::-webkit-scrollbar{
    width: 5px;/* 纵向滚动条*/
    height: 5px;/* 横向滚动条 */
}

.overwapper{background-image: url("./background.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%; width: 100%;
  position: fixed;
  top:0px;left:0px;
  background-size: cover; z-index: 0;

}

.background{
position: absolute;
left: 0px; top:0px; z-index: 10;
  display: flex; width: 100%; height: 100%;
  align-items:center;
  justify-content: center;

}
.ant-btn-primary:hover, .ant-btn-primary:focus{background: #B7034E;border-color: #B7034E;}
.ant-btn-primary{border-color:#B7034E;}
* {
  font-family: 'Roboto Slab', serif;
}