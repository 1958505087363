
.cellphone {
  width: 100%;

  background: #fff;
  border-radius: 10px; overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0,0,0,.4)

}

.feebox{min-height: 500px;}
.desktop {
  width: 1024px;
  margin: auto;
  background: #fff; 
  border-radius: 10px; overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0,0,0,.4)
}
.thankyou{margin-top: 30px;}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{padding:0px !important;}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{width:33.3%; color:#888;}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab-active{color:#ffb521;}

.ant-tabs-nav{width: 100%;}

.ant-tabs-ink-bar{height:3px; background: #ffb521}
.toptitle{width:100%; height:70px; background: #ffb521; margin-bottom: 30px;}

.toptitle h1{color:#fff; font-weight: bold; line-height: 70px; text-transform: uppercase; padding-left: 52px;font-size: 24px; float:left;letter-spacing: 1px;}

.tablist{ width: calc( 100% - 120px ); overflow: hidden; margin-left:60px; }
.tabunit{width:calc( 33.3% - 24px );float: left; margin-left:12px; margin-right:12px; border-bottom: solid 3px #ccc;}
.tabunit p{font-size:18px; font-weight: bold;; color:#ccc; text-transform: uppercase;}
.activeTab{border-bottom: solid 3px #ffb521;}
.activeTab p{color:#ffb521;}
.feedwrapper{text-align: left;}
.clear{ width: 100%; height: 0px; display: block;clear: both;float: none;content:''}

.reviewCardStyle1{width: 100%; font-size:15px;}

@media screen and (max-width: 768px) {
  ant-card-type-inner .ant-card-body {
      padding: 16px 24px;
      padding-bottom: 300px;
  }
  .backimg{display: block;}


  .feedbox{padding-bottom: 10px;}
  .desktop{width:calc( 100% - 20px );  margin-left:10px;margin-bottom: 30px;}
  .tablist{ width: calc( 100% - 40px ); margin-left:20px; }
  .toptitle h1{font-size:20px;padding-left: 24px;}
  .tabunit{width:calc( 33.3% - 12px );margin-left:6px; margin-right:6px; }

}

