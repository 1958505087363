@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patua+One&display=swap);



@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Pacifico-Regular.9b94499c.ttf); /* IE9 Compat Modes */
}

.textbox .bottomtext{text-align:left;font-style:italic; font-size:14px; width:100%; margin-top: 50px;padding-bottom: 40px; z-index: 10; position: relative;}
.textbox{text-align: left; padding-left:52px;padding-top: 30px;float: left; width: 100%;}
.textbox p{font-size:18px; margin-bottom: 0px;}
.textbox .searchbar{width: 400px; margin-top: 20px; }

.textbox .ant-btn-primary{background-color: #ffb521; border-color:#f1a91b}

.orderidamazon{width:50%; max-width: 600px; padding:5px; background: #ccc; margin-top:20px;}

@media screen and (max-width: 768px) {
  .textbox{width:100%;}
  .orderidamazon{width:100%;}
}

.desktop .ant-btn-primary:hover, .ant-btn-primary:focus{

    border-color: rgb(252, 92, 15);
}


.sliderimg{width: 400px; position: absolute; right:60px; bottom:90px; background: #eee; padding:8px 0;}
.sliderimg img{width: 400px; height:auto;}
.feedwrapper .ant-btn-primary{border-color:#de9d1a}
.contentbox .ant-btn-primary{border-color:#de9d1a}
.ant-card-bordered{border:none !important;}
.textbox .ant-input-group .ant-input{background: #eee}
.backimg{position: absolute;    right: 140px;
    bottom: 50px; width: 200px; z-index: 0}
    .backimg img{width: 300px}
.questitle{font-size: 20px; font-weight: bold; color:#0fb6fc;margin-bottom: 0px;}
.opt {width: 60%;}
.opt span{font-size:16px;}
.feedwrapper{padding-left: 52px; margin-bottom: 30px;}
.feedbox .mybtn{background-color: rgb(252, 92, 15); font-size:18px; height: 40px; width: 200px}
.feedbox .mybtna{background-color: #333; font-size:18px;font-weight: bold; height: 60px;float: none; padding:0 20px; margin:0 auto; border-radius: 20px; width: 240px; border:solid 3px #666;}
.feedbox .mybtna .anticon-amazon{line-height: 66px; font-size: 28px; float: left; margin-right: 10px; color:orange;}
.feedbox .mybtna p{float: left; line-height: 56px; font-style: italic;}
.feedbox .mybtna:hover{border:solid 3px orange; background: #000}
.feedbox .mybtna span{line-height: 40px;}

.mainbtnwrapper{background: #eee; width: 100%; padding:0 30px; margin-bottom: 20px; text-align: center; border-radius: 10px;}


.mybtn span{line-height: 40px;}
.mydefaultbtn{line-height: 40px;font-size:18px !important; margin-right: 10px;height: 40px !important; width: 140px}
.mydefaultbtn span{line-height: 40px;color:#666}
.contentbox{ margin-bottom: 30px; }
.feedbox{padding-top: 30px; padding-bottom: 40px;}
.feedbox .ant-radio-wrapper{    border-radius: 5px;
    background: #eee;
    padding: 10px;margin-top:10px;}
.anticon-star{margin-top:20px;}
#reviewb{padding-left:52px; padding-right: 52px}
.reviewCardStyle{font-size:16px; width: 700px; margin-bottom: 10px;}
.feedbox .ant-radio-wrapper span{font-size:16px;}
.feedbox .myinput{width: 400px;margin-bottom: 20px;}

.frozeninput .ant-input{ border:none; background: transparent;width: 400px; color:#333;}
.anticon-star svg{width: 1.5em; height:1.5em;}
.contentbox .ant-upload{}
.thankyou p.thankyou{font-family: 'Courgette',  cursive; font-size: 26px; line-height:40px;margin:0px !important;color:#333;}
.thankyou p{margin-bottom: 0px;}
.contentbox .ant-upload-list{width: 300px}

.thankyou .anticon-star{margin-top: 0px !important;}
.feedbox .mytextarea{font-size:18px; width: 700px; height: 100px;}

.labela{float:left;margin-right:10px;}
.orderside{font-size: 16px;
    color: #fff;
    line-height: 30px;
    padding-top: 20px;
    float: right;
    margin-right: 30px;}
.image_list{ padding:0px;}
.image_unit{object-fit: cover; width:120px; height:120px; margin-right:10px;}
.thankyou .anticon-delete{display: none !important;}
.resultblock{padding-left:0px;margin-top:20px;}
.resultblock .anticon-star svg{width: 1em}
.resultblock .ant-rate-star:not(:last-child){margin-right: 3px;}
.resultblock>li{list-style: none; padding-left: 10px;width: 360px;}
.resultblock>li{border-bottom: solid 1px #eee}
.resultblock p{font-family: Arial; font-size: 16px; color:#666; line-height: 30px;}
.resultblock label{font-size: 16px;line-height: 30px;font-weight: bold}
 .bottomtextb{margin-top:20px;width:400px}
.screenshotamazon{width: 400px}
.reviewCardStyle1.special1{width: 400px}
.reviewCardStyle1 strong{color:#cc3333;}



@media screen and (max-width: 768px) {
 .bottomtextb{margin-top:20px;width:100%}
.screenshotamazon{width: 100%}
.special1{width:100%}
.textbox{padding-left:0px;}
.sliderimg{position: relative; margin-left:60px; margin-top: 120px; width: 100%}
.sliderimg img{width: 100%}
.backimg{display: none !important;}

.opt {width: 100%;}
.orderside{float: left; color:#666; margin-left: 23px;display: none;}

.feedbox .myinput{width: 100%;}
#reviewb{padding-left:0px; padding-right: 0px;}
.reviewCardStyle{width: 100%}
.feedwrapper{padding-left:0px;}
.toptitle h1{padding-left: 22px;float:left;display: block;}
.feedbox .mybtn{width: 100%;}
.feedbox .mybtna {width: auto;}
.mydefaultbtn{width: 100%; margin-left: 0px; margin-bottom: 20px}
.textbox .searchbar{width: 100%; margin-top: 20px; }
.forthanking{display: block}
.thankyou{padding-bottom: 20px; padding-top:0px;}
.feedboxb{padding-top:0px; padding-bottom: 10px;}
.feedbox .mainbtnwrapper .mybtna{ width: 300px}

}

@media screen and (max-width: 480px) {
  /*.feedbox .mybtna p{font-size: 13px}*/
  .feedbox .mybtna{ margin: 0 auto;
    width: 240px;
    display: block;}
  .feedbox .mainbtnwrapper .mybtna{ width: 100% }
}


  .productUnit{
     width: 100%; max-width: 400px; min-height: 150px;
  }
 .productUnit img{max-width: 130px; float: right;}
 .productUnit a.productUnitDetalLink { position: absolute; left: 20px; bottom: 20px;}
 .productUnit a.productUnitDetalLink:hover{ text-decoration:underline; }
.feedwrapper .addressInput{margin-top: 10px; max-width: 600px;}

.rewardChoose .ant-radio-wrapper {
  border: 4px solid #ccc;
  border-radius: 8px;
  padding: 20px;
}

.rewardChoose .ant-radio-wrapper-checked {
  border: 4px solid #1890ff;
  border-radius: 8px;
  padding: 20px;
}

.radio-custom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.radio-custom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}
.uploadImageTips { text-align: left; }
@media screen and (max-width: 480px) {
  .productUnit{
     width: 100%; 
  }
   .imageuploader{ width: 104px; margin:0 auto; }
   .uploadImageTips { text-align: center; }
}
.imageuploader .anticon svg{width: 28px; height: 28px;}
.tips{    margin-top: 20px;
    display: block;
    max-width: 600px;}
    .rewardText strong{color: #cc3333;}




.cellphone {
  width: 100%;

  background: #fff;
  border-radius: 10px; overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0,0,0,.4)

}

.feebox{min-height: 500px;}
.desktop {
  width: 1024px;
  margin: auto;
  background: #fff; 
  border-radius: 10px; overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0,0,0,.4)
}
.thankyou{margin-top: 30px;}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{padding:0px !important;}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{width:33.3%; color:#888;}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab-active{color:#ffb521;}

.ant-tabs-nav{width: 100%;}

.ant-tabs-ink-bar{height:3px; background: #ffb521}
.toptitle{width:100%; height:70px; background: #ffb521; margin-bottom: 30px;}

.toptitle h1{color:#fff; font-weight: bold; line-height: 70px; text-transform: uppercase; padding-left: 52px;font-size: 24px; float:left;letter-spacing: 1px;}

.tablist{ width: calc( 100% - 120px ); overflow: hidden; margin-left:60px; }
.tabunit{width:calc( 33.3% - 24px );float: left; margin-left:12px; margin-right:12px; border-bottom: solid 3px #ccc;}
.tabunit p{font-size:18px; font-weight: bold;; color:#ccc; text-transform: uppercase;}
.activeTab{border-bottom: solid 3px #ffb521;}
.activeTab p{color:#ffb521;}
.feedwrapper{text-align: left;}
.clear{ width: 100%; height: 0px; display: block;clear: both;float: none;content:''}

.reviewCardStyle1{width: 100%; font-size:15px;}

@media screen and (max-width: 768px) {
  ant-card-type-inner .ant-card-body {
      padding: 16px 24px;
      padding-bottom: 300px;
  }
  .backimg{display: block;}


  .feedbox{padding-bottom: 10px;}
  .desktop{width:calc( 100% - 20px );  margin-left:10px;margin-bottom: 30px;}
  .tablist{ width: calc( 100% - 40px ); margin-left:20px; }
  .toptitle h1{font-size:20px;padding-left: 24px;}
  .tabunit{width:calc( 33.3% - 12px );margin-left:6px; margin-right:6px; }

}


@import Background from './background.jpg';
html, body{
    /* box-sizing: content-box; */
    width: 100%;
    height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}
#root{
    /* box-sizing: content-box; */
    width: 100%;
    height: 100%
}

::-webkit-scrollbar{
    width: 5px;/* 纵向滚动条*/
    height: 5px;/* 横向滚动条 */
}

.overwapper{background-image: url(/static/media/background.9ec3b401.jpg);

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%; width: 100%;
  position: fixed;
  top:0px;left:0px;
  background-size: cover; z-index: 0;

}

.background{
position: absolute;
left: 0px; top:0px; z-index: 10;
  display: flex; width: 100%; height: 100%;
  align-items:center;
  justify-content: center;

}
.ant-btn-primary:hover, .ant-btn-primary:focus{background: #B7034E;border-color: #B7034E;}
.ant-btn-primary{border-color:#B7034E;}
* {
  font-family: 'Roboto Slab', serif;
}
